import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { ref } from 'vue';
export default () => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'country',
      value: '',
      placeholder: '请输入国家',
    },
    {
      type: 'input',
      prop: 'category',
      value: '',
      placeholder: '请输入产品大类',
    },
    {
      type: 'input',
      prop: 'varietyName',
      value: '',
      placeholder: '请输入品种',
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '国家',
      prop: 'countryName',
      width: 202,
    },
    {
      label: '产品大类',
      prop: 'category',
      width: 313,
    },
    {
      label: '品种',
      prop: 'varietyName',
      minWidth: 600,
    },
  ];
  return { tableRef, filterOptions, columnList };
};
